import './App.css';
import Layout from './components/Layout/Layout';
import { ThemeProvider, createTheme } from '@material-ui/core';

function App() {
  const theme = createTheme();
  return <ThemeProvider theme={theme}>
    <Layout />
  </ThemeProvider>
}

export default App;
