import React from 'react';
import './hero-section.css';
import { Container, Row, Col } from 'reactstrap';
import Typed from 'react-typed';

const HeroSection = () => {
    return (
        <section className='hero_section' id='home'>
            <Container>
                <Row>
                    <Col lg='8' md='6'>
                        <div className='hero_content'>
                            <h1 className='mb-4 big orange'>
                                <span className="white fade-in">Welcome! I'm &nbsp;</span>
                                <Typed
                                    strings={['Aryan,']}
                                    typeSpeed={100}
                                    showCursor={false}
                                    cursorChar="|"
                                />
                                <br /><span className="white smaller padleft fade-in">a <span className='hero_title'>Software Engineering </span>student at the University of Waterloo.</span>
                            </h1>
                            <p className="white padleft fade-in">
                                I have a great interest in leveraging software to build real world solutions to complex problems. Throguh previous internships and award winning hackathon projects, I have gained a strong background in <span className='hero_title'>Machine Learning </span>and <span className='hero_title'>Full-Stack Development  </span>
                            </p>


                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default HeroSection;
