import React from 'react';
import { Tabs, Tab, Box, Typography } from '@material-ui/core';
import './experience.css';
import { Container, Row, Col } from 'reactstrap';

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Experience() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section id="experience" className='experience-container'>
            <Container>
                <Row>
                    <Col lg='12' className='mb-5'>
                        <h2 className="stylish-underline">Experience</h2>
                    </Col>
                    <Row className='experience_content_wrapper'>
                        <Col lg='4' md='12' sm='12' className='tabs-container'>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                            >
                            <Tab label="UWaterloo - Vision and Image Processing Lab" />
                            <Tab label="Toyota Motor North America" />
                                <Tab label="WAT.ai" />
                                <Tab label="N Harris Computers" />
                            </Tabs>
                        </Col>
                        <Col lg='8' md='12' sm='12' className='details-container'>
                        <TabPanel value={value} index={0}>
                                <Typography className="job-title">
                                   Undergraduate Research Assistant
                                </Typography>
                                <Typography className="job-duration">Apr 2024 - Present</Typography>
                                <ul className="job-description">
                                <li> Researching deep learning models for wildfire risk prediction under the supervision of Professor Xu </li>
                                </ul>
                            </TabPanel>
                        <TabPanel value={value} index={1}>
                                <Typography className="job-title">
                                   Software Engineer Intern
                                </Typography>
                                <Typography className="job-duration">Jan 2024 - April 2024</Typography>
                                <ul className="job-description">
                                <li> Implemented a vehicle defect detection service using Python and YOLOv4 CNN to fine-tune on  user-labeled images, saving $94,000 per year in licensing fees </li>
<li> Built event-driven backend service to process vehicle position and initiate model inference using RabbitMQ, SQL and C# </li>
<li> Developed Redis-based caching service to retrieve model features, reducing latency by 20% over thousands of requests </li>
<li> Created plugins using C# to process 1M+ model inferences and compute performance metrics </li>
                                </ul>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Typography className="job-title">
                                    Machine Learning Developer
                                </Typography>
                                <Typography className="job-duration">Nov 2023 - Present</Typography>
                                <ul className="job-description">
                                <li>First author of research paper on wildfire prediction model published at the Canadian Undergraduate Conference on AI</li>
<li>Architected geospatial data pipeline using Pandas to segment regions in British Columbia based on latitude and longitude</li>
<li>Wrote Python script to aggregate weather data and automate CSV uploads to Google Cloud Storage buckets</li>
<li>Integrated Scikit-learn Grid Search, XGBoost and feature importance into the training pipeline to predict burn area</li>
                                </ul>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <Typography className="job-title">
                                    Software Developer Intern
                                </Typography>
                                <Typography className="job-duration">May 2023 - Aug 2023</Typography>
                                <ul className="job-description">
                                <li>Developed user-configurable columns in customer account search using TypeScript to optimize data payload and reduce latency by 30% across 1000 results; presented the feature to 70+ RD team members</li>
<li>Led system-wide UI component upgrade and optimized datagrid loading with Vue, reducing memory usage by 40%</li>
<li>Built server-side validation using MySQL, TypeScript to filter out inactive accounts, ensuring accurate billing entries</li>
                                </ul>
                            </TabPanel>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </section>
    );
}