import React, { useRef, useEffect } from 'react'
import { Container } from 'reactstrap'
import './header.css'

const navLinks = [
    {
        display: "About",
        url: "#about"
    },
    {
        display: "Experience",
        url: "#experience"
    },
    {
        display: "Projects",
        url: "#projects"
    },
    {
        display: "Awards",
        url: '#awards'
    },
    {
        display: "Links",
        url: "#contact"
    }
]

const Header = () => {
    const headerRef = useRef(null)

    useEffect(() => {
        const handleScroll = () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                headerRef.current.classList.add('header_shrink');
            } else {
                headerRef.current.classList.remove('header_shrink');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = e => {
        e.preventDefault();
        const targetAttr = new URL(e.target.href).hash; // This will give you '#about'
        const location = document.querySelector(targetAttr).offsetTop; // Note the correction from 'offSetTop' to 'offsetTop'


        window.scroll({
            left: 0,
            top: location - 70
        })
    }


    return <header className="header" ref={headerRef}>
        <Container>
            <div className="navigation d-flex align-items-center justify-content-between">
                <div className="logo"><h5>Aryan Sharma</h5></div>
                <div className="nav_menu">
                    <ul className="nav_list">
                        {
                            navLinks.map((item, index) => {
                                return (
                                    <li className="nav_item" key={index}>
                                        <a href={item.url} onClick={handleClick}>{item.display}</a>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        </Container>
    </header>
}

export default Header