import React from 'react'
import { Fragment } from 'react'
import Header from '../header/Header'
import HeroSection from '../hero-section/HeroSection'
import About from '../about/About'
import Experience from '../experience/Experience'
import Projects from '../projects/Projects'
import Awards from '../awards/Awards'
import Contact from '../contact/Contact'

const Layout = () => {
  return (<Fragment>
    <Header />
    <HeroSection />
    <About />
    <Experience />
    <Projects />
    <Awards />
    <Contact />
  </Fragment>)
}

export default Layout