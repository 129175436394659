import React from 'react';
import './awards.css';
import { Container, Row, Col } from 'reactstrap';
import { Typography } from '@material-ui/core';

export default function Awards() {
    return (
        <section id="awards" className='awards-container'>
            <Container>
                <Row>
                    <Col lg='12' className='mb-5'>
                        <h2 className="stylish-underline">Awards</h2>
                    </Col>
                    <Row className='awards_content_wrapper'>
                        <Col lg='12' md='12' sm='12' className='awards-details-container'>
                            <Typography className="job-title">
                                <ul className="job-description">
                                    <li className='space'>Placed top 1.5% out of 22000+ students in University of Waterloo’s Euclid Math Contest, School Champion</li>
                                    <li className='space'>Placed top 0.2% out of 12000+ students in University of Waterloo’s Fermat Math Contest, School Champion</li>
                                    <li className='space'>Best Use of AI Agents, Fetch.ai $2000 Sponsor Prize at LA Hacks, Southern California’s Largest Hackathon</li>
                                    <li className='space'>Best Use of Google Technologies, Google Sponsor Prize at Canada’s Largest AI Hackathon (UofT)</li>
                                    <li className='space'>Dean's Honours List Distinction (top 10% academic performance in class)</li>
                                    <li className='space'>4 Time HOSA Medical Math National Champion</li>
                                    <li className='space'>3 Time Mathematica National Champion and World Mathematical Olympiad silver medalist</li>
                                    <li className='space'>Elite baseball player for the Ontario Blue Jays (6 years)</li>
                                    <li className='space'>Level 7 RCM with First Class Distinction</li>
                                </ul>
                            </Typography>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </section>
    );
}
