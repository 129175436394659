import React from 'react';
import './contact.css';
import { Row, Col } from 'reactstrap';
import { AiFillLinkedin } from 'react-icons/ai';
import { HiOutlineMail } from 'react-icons/hi';
import { FaGithub } from 'react-icons/fa';

const Contact = () => {
    return (
        <section id='contact' className='contact-full-width'>
            <Row noGutters className='contact_content_wrapper'>
                <Col lg='4' md='4' sm='12' className='text-center mb-4 contact-item'>
                    <a href="https://www.linkedin.com/in/aryan-s1/" target="_blank" rel="noopener noreferrer" className='contact_link'>
                        <div className='contact_icon'>
                            <AiFillLinkedin size='1.5em' />
                        </div>
                        <div className='contact_text'>
                            <h5>LinkedIn</h5>
                        </div>
                    </a>
                </Col>
                
                <Col lg='4' md='4' sm='12' className='text-center mb-4 contact-item'>
                    <a href="mailto:a98sharm@uwaterloo.ca" className='contact_link'>
                        <div className='contact_icon'>
                            <HiOutlineMail size='1.5em' />
                        </div>
                        <div className='contact_text'>
                            <h5>Email</h5>
                        </div>
                    </a>
                </Col>

                <Col lg='4' md='4' sm='12' className='text-center mb-4 contact-item'>
                    <a href="https://github.com/Ari1029" target="_blank" rel="noopener noreferrer" className='contact_link'>
                        <div className='contact_icon'>
                            <FaGithub size='1.5em' />
                        </div>
                        <div className='contact_text'>
                            <h5>GitHub</h5>
                        </div>
                    </a>
                </Col>
            </Row>
        </section>
    );
}

export default Contact;