import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import './projects.css'
import { IconContext } from 'react-icons'
import {IoIosGitNetwork} from 'react-icons/io'
import {MdOutlineWeb} from 'react-icons/md'
import {AiFillGithub} from 'react-icons/ai'

const projectsData = [
    {
        title: 'AI-Powered Robotic Arm Assistant',
        description: "Built speech recognition system using Distil-Whisper and multithreading for concurrent audio recording and transcription. Utilized OpenCV to annotate an image with the centroids of medical tools, providing Google’s Gemini Pro with positional context to generate robot code for object movement based on user requests",
        technologies: 'Python, Google Gemini, OpenCV, OpenAI API, LLMs',
        link: 'https://github.com/Ari1029/AI-Robotic-Arm-LA-Hacks'
    },
    {
        title: 'Tuberculosis Screener',
        description: "Implemented conversation history and Retrieval Augmented Generation (RAG) with LangChain, a vector database, Gemini and Google Cloud Platform to retrieve medical documents relevant to patient symptoms. Designed React frontend and REST API using Flask to showcase voice translations and generated text",
        technologies: 'Python, Flask, RAG, LLMs, React',
        link: 'https://github.com/Ari1029/Tuberculosis-Screener'
    },
    {
        title: 'Image Captioning',
        description: "Leveraged OpenAI's CLIP visual transformer on COCO dataset to extract visual embeddings, which were projected to StableLM's latent space via a mapping network. Resulting output is used as a prefix for LLM, which generates accurate captions.",
        technologies: 'PyTorch, NLTK, CLIP, LLMs\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
        link: 'https://github.com/Ari1029/Stable-Lens'
    },
]

const webData = [
    {
        title: 'Compiler for Subset of C++',
        description: "Leveraged OOP design bottom-up parser. Implemented assembly code generation for features via systematic stack management. Developed assembler by applying maximal munch algorithm and encoding instructions with bitwise operations",
        technologies: 'C++',
        link: ''
    },
    {
        title: 'TechRoom',
        description: "Technology-based e-commerce website. Implemented Stripe's API payment gateway to process checkout requests. Developed middleware via Passport & sessions to authenticate users.",
        technologies: 'Express.js, EJS, MongoDB',
        link: 'https://github.com/Ari1029/TechRoom'
    },
    {
        title: 'Mega Verse',
        description: 'Decentralized app which allows users to connect to MetaMask wallets and mint NFTs on OpenSea Testnet. Server-side rendering to fetech user/NFT collection data. Multipage, responsive frontend.',
        technologies: 'React, Tailwind CSS, Next.js, ThirdWeb',
        link: 'https://github.com/Ari1029/Mega-Verse'
    }
]

const Projects = () => {
    return <section id='projects'>
        <Container>
            <Row>
                <Col lg='12' className='projects_top mb-5'>
                    <h2 className='stylish-underline'>Projects</h2>
                </Col>
                {projectsData.map(project => (
                        <Col lg='4' md='6' sm='6' key={project.title}>
                            <div className='single_project'>
                                <IconContext.Provider value={{ size: "2rem", className: "blue" }}>
                                    <div>
                                        <IoIosGitNetwork />
                                    </div>
                                </IconContext.Provider>
                                <div style={{display: 'flex', alignItems:'baseline'}}>
                                <h3 className='purple'>{project.title} &nbsp;
                                    <a href={project.link}><AiFillGithub size='1em'/></a>
                                </h3></div>
                                <p>
                                    {project.description}
                                </p>
                                <ul className="job-description">
                                    <li>
                                        {project.technologies}
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    ))}
                    {webData.map(project => (
                        <Col lg='4' md='6' sm='6' key={project.title}>
                            <div className='single_project'>
                                <IconContext.Provider value={{ size: "2rem", className: "blue" }}>
                                    <div>
                                        <MdOutlineWeb />
                                    </div>
                                </IconContext.Provider>
                                <div style={{display: 'flex', alignItems:'baseline'}}>
                                <h3 className='purple'>{project.title} &nbsp;
                                <a href={project.link}><AiFillGithub size='1em'/></a>
                                </h3></div>
                                <p>
                                    {project.description}
                                </p>
                                <ul className="job-description">
                                    <li>
                                        {project.technologies}
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    ))}
            </Row>
        </Container>
    </section>
}

export default Projects